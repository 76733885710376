import React, { Component } from "react"
import Helmet from "react-helmet";
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import { StaticQuery, graphql } from "gatsby"

export default class CookieConsent extends Component {

	static contextType = I18nextContext;	

	render() {
		const language = this.context.language || 'es';

		return <StaticQuery query={graphql`
			query CookieInfoQuery {
				site {
					siteMetadata {
						title
						cookiesPolicyArticleInfo {
							lang
							path
						}
						gtmTrackingId
						gaTrackingId
					}
				}
			}`}
			render={data => {
				const website = data.site.siteMetadata.title;
				const cookiePolicyArticleInfo = data.site.siteMetadata.cookiesPolicyArticleInfo.find(article => (article.lang === language));
				const cookiesPolicyUrl = (cookiePolicyArticleInfo) ? cookiePolicyArticleInfo.path: '';
				
				return <Helmet>
							<script async src='//www.google-analytics.com/analytics.js'></script>
							<script type="text/javascript" src="//www.freeprivacypolicy.com/public/cookie-consent/4.0.0/cookie-consent.js" charset="UTF-8"></script>
							<script type="text/javascript" charset="UTF-8">{`
								document.addEventListener('DOMContentLoaded', function () {
									cookieconsent.run({	"notice_banner_type":"interstitial",
														"consent_type": "implied",
														"palette":"light",
														"language":"${language}",
														"page_load_consent_levels":["strictly-necessary"],
														"notice_banner_reject_button_hide":false,
														"preferences_center_close_button_hide":false,
														"page_refresh_confirmation_buttons":false,
														"website_name":"${website}",
														"website_privacy_policy_url":location.protocol + '//' + location.host + "${cookiesPolicyUrl}"});
								});
							`}</script>
							
							<script type="text/plain" cookie-consent="tracking">{`
								window.dataLayer = window.dataLayer || [];
						
								(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
								new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
								j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
								'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
								})(window,document,'script','dataLayer','${data.site.siteMetadata.gtmTrackingId}');

							`}</script>
							
							
							<noscript>{`Cookie Consent by <a href="https://www.freeprivacypolicy.com/" rel="nofollow noopener">Free Privacy Policy Generator website</a>`}</noscript>
							
			</Helmet>}}/>
	}
}

