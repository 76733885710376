import React, { Component } from "react"
import { withTranslation } from 'gatsby-plugin-react-i18next';
import { StaticQuery, graphql, Link } from "gatsby"


class PageFooter extends Component {
	render() {
		const { t } = this.props;
		const language = this.props.i18n.language;

		function getLink(link, linkPath) {
			let result;
			if (link.dest === "blank" && link.resource !== null) {
				result = (<a target="_blank" rel="noreferrer" className={'clic_menu_footer'} href={linkPath}>{t(link.name)}</a>);
			}else if (link.dest === "blank") {
				result = (<a  target="_blank" rel="noreferrer" className={'clic_menu_footer'} href={linkPath+ '/'}>{t(link.name)}</a>);
			} else {
				result = (<Link to={linkPath + '/'}  className={'clic_menu_footer'}>{t(link.name)}</Link>);
			}
			return result;
		}

		return (
			<footer className="pt-5 pb-5">
				<div className="container">
					<div className="row ml-md-4 mr-md-4">
						<StaticQuery query={graphql`
							query FooterQuery {
								site {
									siteMetadata {
										footer {
											name
											styleClass
											articles {
												name
												article
												resource
												dest
											}
										}
									}
								}
								allLocalizedArticle {
									edges {
										node {
											articleId
											path
											lang
										}
									}
								}
								allContentResource {
									nodes {
										name
										lang
										remoteFile {
											publicURL
										}
									}
								}
							}
						`}
						render={data => (
							data.site.siteMetadata.footer.map(section => (
								<div className={section.styleClass} key={section.name}>
									<h5>{t(section.name)}</h5>
									<ul className="list-unstyled small mt-2">
										{
											section.articles.map(link => {
												let linkPath;
												if (link.article) {
													const article = data.allLocalizedArticle.edges.find(article => (article.node.articleId === link.article) && (article.node.lang === language))
													if (article) {
														linkPath = article.node.path;
													} else {
														return <React.Fragment key={link.name}/>
													}
												} else if (link.resource) {
													const resource = data.allContentResource.nodes.find(resource => ((resource.name === link.resource) && (resource.lang === language)));
													if (resource) {
														linkPath = resource.remoteFile.publicURL;
													} else {
														return <React.Fragment key={link.name}/>
													}
												} else if (link.url) {
													return <li key={link.name}><a href={link.url + '/'}>{t(link.name)}</a></li>
												} else {
													return <React.Fragment key={link.name}/>
												}
												return (<li key={link.name}>
													{ getLink(link, linkPath) }
													</li>)
											})
										}
									</ul>
								</div>
							))
						)}/>
					</div>
					<hr/>
					<div className="row justify-content-end text-center text-sm-right align-items-center mr-2">
						<div className="col-12 col-sm-auto">
							<span>&reg; caminobarrancodemasca.com</span>
						</div>
						<div className="col-8 col-sm-auto">
							<a href="http://www.webtenerife.com/que-visitar/otros-espacios-naturales/parque+rural+de+teno.htm">
								<span className="sr-only">Parque Rural Teno</span>
								<span className="icon-logo-parque-rural-teno"/>
							</a>
						</div>
						<div className="col-4 col-sm-auto">
							<a href="https://www.tenerife.es">
								<span className="sr-only">Cabildo de Tenerife</span>
								<span className="icon-logo-cabildo-tfe"/>
							</a>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default withTranslation('PageFooter')(PageFooter);
