import React from 'react'
import NavigationBar from '../components/NavigationBar'
import PageFooter from '../components/PageFooter'
import CookieConsent from '../components/CookieConsent'

export default function Layout({ children }) {
  return (
	<div>
		<div className="container principal">
			<NavigationBar />
			<main>
				{children}
			</main>
		</div>
		<PageFooter/>
		<CookieConsent/>
	</div>
  )
}